<template>
    <div class="">
        <div v-if="editMode=='read'">
            <div class="row">
                <div class="col col-5">
                    <vueper-slides
                        v-if="originalMediaArray && originalMediaArray[0] && originalMediaArray[0].url != ''"
                        :slide-ratio="1 / 2" 
                        :visible-slides="1"
                        fixed-height="200px"
                    >
                        <template #arrow-left> <CustomIcon :size="24" color="white" glyph="mdi-chevron-left" />  </template>
                        <template #arrow-right> <CustomIcon :size="24" color="white" glyph="mdi-chevron-right" /> </template>
                        <vueper-slide
                            v-for="(slide, index) in originalMediaArray"
                            :key="index"
                            :title="slide.title"
                            :image="slide.url"
                            style="background: cover;"
                            >
                        </vueper-slide>
                    </vueper-slides>
                </div>
                <div class="col col-7" style="display: flex; flex-direction: column; justify-content:space-evenly">
                    <h2 v-if="isRanked"> # {{index+1}} - {{ originalTitle }} </h2>
                    <h2 v-if="!isRanked"> {{ originalTitle }} </h2>
                    <div v-if="item.points || item.votes">
                        <p v-if="item.points" class="mb-0"> Total Points: {{ item.points }} </p>
                        <p v-if="item.votes" class="mb-0"> Total Votes: {{ item.votes }} </p>
                    </div>

                    <p v-if="originalDescription"> {{ originalDescription }} </p>
                    <div v-if="originalLinkArray && originalLinkArray.length > 0" class="mt-3" style="align-self:flex-start; width:100%; overflow-x: hidden;">
                        <div v-for="(l, index) in originalLinkArray" :key="index" style="display:flex; justify-content: flex-start;" class="">
                            <a :href="l.url" target="_blank">{{ l.url | truncate(40) }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editMode=='write'" class="row">
            <div class="col col-6" style="">
                <div v-for="(i, index) in editMediaArray" :key="index" style="display:flex; justify-content: flex-end;" class="">
                    <img :src="i.url" style="height:50px;" class="pr-1"/>
                    <v-text-field v-model="i.url" label="Image URL"/>
                    <CustomIcon @click="editMediaArray.splice(index,1)" :size="30" color="gray" glyph="mdi-alpha-x" class="" /> 
                </div>
                <div style="display:flex; justify-content: center;">
                    <CustomIcon @click="addURL()" toolTip="Add Image URL" color="green" glyph="mdi-plus" /> 
                </div>
            </div>
            <div class="col col-6">
                <v-text-field v-model="editRanking" label="Ranking" />
                <v-text-field v-model="editTitle" label="Title" ref="itemTextRef" />
                <v-text-field v-model="editDescription" label="Description" ref="itemTextRef" />
                <div v-for="(l, index) in editLinkArray" :key="index" style="display:flex; justify-content: flex-start;" class="">
                    <v-text-field v-model="l.url" label="Link URL"/>
                    <CustomIcon @click="editLinkArray.splice(index,1)" :size="30" color="gray" glyph="mdi-alpha-x" class="" /> 
                </div>
                <div style="display:flex; justify-content: center;">
                    <CustomIcon @click="addLink()" toolTip="Add Link" color="green" glyph="mdi-plus" /> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '/src/utils/api';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            // editContent: null,
            editRanking: this.index+1,
            editDescription: this.item.desription,
            editMediaArray: this.item.media_array,
            editLinkArray: this.item.link_array,
            editTitle: this.item.title,
            // originalContent: null,
            originalRanking: this.index+1,
            originalDescription: this.item.description,
            originalMediaArray: this.item.media_array,
            originalLinkArray: this.item.link_array,
            originalTitle: this.item.title,
        };
    },
    mounted() {
        // this.resetContent();
    },
    watch: {
        editMode(newValue, oldValue) {
            if(newValue == "write") {
                // Refresh editRanking in case the item was dragged around prior to editing this record.
                this.editRanking = this.index+1,

                // Highlight text region so user can start typing as soon as they enter editing mode.
                this.$nextTick(() => {
                    this.$refs.itemTextRef.focus();
                });
            }
        }
    },
    methods: {
        async resetContent() {
            this.editDescription = this.originalDescription;
            this.editLinkArray = this.originalLinkArray;
            this.editMediaArray = this.originalMediaArray;
            this.editTitle = this.originalTitle;
            this.editRanking = this.originalRanking;
        },
        addLink() {
            this.editLinkArray = this.editLinkArray || [];
            this.editLinkArray.push({url:""});
        },
        async save() {
                // let newContent = JSON.stringify(this.editContent);
                // console.log("rankingHasChanged",rankingHasChanged, this.$refs.itemEditor.editRanking, "--", this.index+1);
                // console.log("contentHasChanged",contentHasChanged, "--", newContent);
                let payload = {
                    listItemId: this.item.listItemId,
                    description: this.editDescription,
                    linkArray: JSON.stringify(this.editLinkArray),
                    mediaArray: JSON.stringify(this.editMediaArray),
                    title: this.editTitle,
                    ranking: this.editRanking
                }
                return api.postAuthOptional('update-list-item', payload).then(() => {
                    this.originalDescription = this.editDescription;
                    this.originalLinkArray = this.editLinkArray;
                    this.originalMediaArray = this.editMediaArray;
                    this.originalTitle = this.editTitle;

                    // Reload from API to ensure UI is representing same order as database.
                    if (this.editRanking != this.originalRanking)
                        this.refreshList(this.editRanking);
                });
        },
        addURL() {
            this.editMediaArray = this.editMediaArray || [];
            this.editMediaArray.push({type:"img", url:""});
        }
    },
    props: ['cancelEdit','editMode','isRanked','item','index','refreshList','saveModalContent'],
};
</script>
<style>
    .vueperslide {
        background-size: contain;
        /* background-color: rgba(0,0,0,.87); */
    }
    .vueperslides__arrow {
        background-color: rgba(100, 100, 100, .8);
    }
    .vueperslides__bullet .default {
        background-color: rgba(0, 0, 0, 0.3);
        /* 
        border: none;
        box-shadow: none;
        transition: 0.3s; */
        width: 8px;
        height: 8px;
    }

    /* Lifted this code from the browser. Just wanted to turn off the weird box shadow effect. */
    .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
        box-shadow: none;
    }
</style>